<template>
    <div>
        <div class="member-level-title">
            <div class="page-title">
                <span>会员类型设置</span>
                <div class="page-title-card-tips">您可根据自身门店情况，自由编写会员类型名称及为其赋予权限</div>
            </div>
            <button v-if="levels" class="btn" @click="addLevel(levels.length)">新增会员类型</button>
        </div>
        
        <table class="levels-table">
            
            <thead>
            <tr>
                <th>会员类型名称</th>
                <th>开通服务权限</th>
                <th>操作</th>
            </tr>
            </thead>
            
            <tbody>
            
            <template v-for="(level,lk) in levels">
                
                <tr v-if="!level.del" class="level" :key="lk" :data-name="level">
                    
                    <td class="level-input">
                        <at-input v-model="level.name" width="200px"/>
                    </td>
                    
                    <td class="level-select">
                        <a-select class="at-select" v-model="level.permissions" mode="multiple" style="width: 100%"
                                  placeholder="暂未分配权限，请点击选择权限">
                            <template v-for="(name,key) in permissionsList">
                                <a-select-option :key="key" :value="key">{{ name }}</a-select-option>
                            </template>
                        </a-select>
                    </td>
                    <td class="level-del text-center">
                        <a class="level-del-btn" @click="delLevel(level)">删除</a>
                    </td>
                
                </tr>
            
            </template>
            
            </tbody>
        
        
        </table>
        
        <button @click="saveLevel" class="btn btn-submit">保存</button>
    
    
    </div>
</template>

<script>
import AtInput from "../../../components/AtInput";
import api from "../../../repo/api";

export default {
    name: "Level",
    components: {AtInput},
    data() {
        return {
            levels: [''],
            levelLength: 0,
            permissionsList: [],
        }
    },
    mounted() {
        this.getLevels()
        this.getPermissions()
    },
    methods: {
        getNo(key) {
            let idx = 1;
            
            for (let i = 0; i < this.levels.length; i++) {
                
                const item = this.levels[i];
                
                if (item.del) continue;
                
                if (i === key) return idx;
                
                idx++;
            }
            
            return idx;
        },
        getPermissions() {
            api.get('/ast-member/permissions', {
                member_id: this.memberId
            }, (res) => {
                this.permissionsList = res.data.permissions
            })
        },
        getLevels() {
            this.$loading.show()
            
            api.get('/ast-app/member-levels', (data) => {
                
                this.levels = data.data.length ? data.data : []
                
                if (!this.levels.length) this.addLevel()
                
                this.levels.forEach(level => {
                    if (!level.permissions) level.permissions = []
                })
                
                this.$loading.hide()
                
                this.sumLevelLength()
            });
        },
        saveLevel() {
            
            this.$loading.show()
            
            api.post('/ast-app/update-member-levels', {levels: this.levels}, (data) => {
                
                this.$message.data(data)
                
                this.getLevels()
            });
        },
        addLevel(idx) {
            this.levels.splice(idx + 1, 0, {
                name: "",
                permissions: []
            });
            
            this.sumLevelLength()
            
            this.$forceUpdate()
        },
        delLevel(level) {
            
            if (this.levelLength === 1) return;
            
            if (!level.id) {
                
                let idx = this.levels.indexOf(level)
                
                this.levels.splice(idx, 1)
                
                this.sumLevelLength()
                
                this.$forceUpdate()
                
            } else {
                
                this.$confirm({
                    title: '删除会员等级，此等级下用户将变成游客',
                    onOk: () => {
                        level.del = 1
                        
                        this.sumLevelLength()
                        
                        this.$forceUpdate()
                    }
                })
                
            }
        },
        sumLevelLength() {
            
            let length = 0
            
            this.levels.forEach(item => {
                if ((!item.del) || item.del <= 0) length++
            })
            
            
            return this.levelLength = length;
        }
    }
}
</script>

<style lang="less" scoped>
.btn-submit {
    margin-top: 88px;
    width: 82px;
    height: 32px;
    background: #333333;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 32px;
    padding: 0;
}

.member-level-title {
    width: 900px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    
    .page-title {
        margin-bottom: 0;
    }
}

.level-no, .level-input {
    text-align: center;
    padding-right: 40px;
}

.l1-th {
    width: 120px;
}

.level-select {
    padding-right: 80px;
    width: 475px;
}

.level-del {
    width: 60px;
}

.level-del-btn {
    font-weight: 400;
    font-size: 14px;
    color: #F5606D;
    line-height: 16px;
}

.at-select /deep/ .ant-select-selection {
    border-radius: 0;
}

.at-select /deep/ .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 33px;
    line-height: 33px;
    font-size: 14px;
}


.levels-table {
    th {
        font-size: 14px;
        color: #999999;
        line-height: 16px;
        text-align: left;
        font-weight: 400;
        padding-left: 16px;
        padding-bottom: 28px;
        border-bottom: 1px solid #EEEEEE;
    }
    
    tbody {
        tr td {
            padding-top: 20px;
        }
        
        tr:first-child td {
            padding-top: 12px;
        }
    }
    
    
}


</style>